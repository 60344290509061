import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import htmlDecode from "html-entities-decoder";

import Introduction from "../components/Global/IntroductionBg"
import "../components/Services/ServiceTemplate.css"


function ServiceTemplate(props) {
    let data = props.data.wpgraphql.service;
    let services = props.data.wpgraphql.services.edges;
    console.log(props)

    function preloadImage(img) {
        const src = img.getAttribute("data-src");
        if (!src) {
            return;
        }
        img.src = src;
    }

    const imgOptions = {
        threshold: 0,
        rootMargin: "0px 0px 300px 0px"
    };

    useEffect(() => {
        const observer = new PerformanceObserver((list) => {
            let perfEntries = list.getEntries();
            let lastEntry = perfEntries[perfEntries.length - 1];
            console.log(lastEntry.element )
            // Process the latest candidate for largest contentful paint
          });
        observer.observe({entryTypes: ['largest-contentful-paint']});


        let imgObserver = new IntersectionObserver((entries, imgObserver) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                } else {
                    preloadImage(entry.target);
                    imgObserver.unobserve(entry.target);
                }
            })
        }, imgOptions)

        let images = document.querySelectorAll("[data-src]");
        images.forEach(image => {
            imgObserver.observe(image)
        })

    }, [])

    function addLazyLoadHandler(content) {
        let lazyLoadedContent;
        lazyLoadedContent = content.replace(/src="/g, 'data-src="')
        lazyLoadedContent = lazyLoadedContent.replace(/srcset="/g, 'data-srcset="')
        return lazyLoadedContent;
    }

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://nextyounutrition.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://nextyounutrition.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }

        console.log(payload)
        return payload
    }

    return (
        <Layout header={props.data.wpgraphql.menu} footer={props.data.wpgraphql.footer} appointment= {props.data.wpgraphql.template.appCpt} metaData={constructMetaData(data, props.pageContext.currentPage)}  >

            <section>
                <Introduction backImg={data.servicesAcf.backgroundImage} title={htmlDecode(data.title)} />
            </section>
            <section className="container m-auto px-8 md:px-4 my-16 md:my-24">
                <div className="flex flex-wrap lg:flex-no-wrap">
                    <div className="service-wrapper w-full lg:w-1/2 xl:w-4/6" dangerouslySetInnerHTML={{ __html: addLazyLoadHandler(data.content) }}></div>
                    <div className="w-full lg:w-1/2 xl:w-2/6 ss-r-tab mt-16 lg:mt-0 pl-0 lg:pl-8">
                        <div>
                            <div className="ss-side-title">
                                Υπηρεσίες
                            </div>
                            <ul className="ss-nav-ul">
                                {services.map((sc, i) => (
                                    <li key={`nav-sc-${i}`}>
                                        <Link to={`${sc.node.uri.replace("service", "ypiresies")}`} className={props.pageContext.currentPage === sc.node.uri.replace("service", "ypiresies") ? "ss-current-page" : ""}>
                                            {htmlDecode(sc.node.title)}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ServiceTemplate

export const pageQuery = graphql`
    query GET_SERVICE($id: ID!) {
        wpgraphql {
            service(id: $id){
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
                title
                content
                uri
                servicesAcf{
                    backgroundImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2000, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }  
                            }
                        }
                    }
                    featuredImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 900, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                }
            }
            services(first: 20, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
                edges {
                    node {
                        uri
                        title
                    }
                }
            }
            template(id: "cG9zdDoyMDUw"){
                appCpt {
                    ...AppointmentFragment
                }
            }
            menu(id:"bmF2X21lbnU6MjI="){
                menuItems{
                    edges{
                        node{
                            url
                            label
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footer: template(id:"cG9zdDoyMjE5"){
                id
                title
                footerInformationTemplate{
                    address{
                        addressGoogleLink
                        addressLabel
                    }
                    hoursLabel
                    hoursTitle
                    informationTitle
                    supportEmail
                    telephoneNumber
                }
                footerAcf{
                    googlaMapsImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 750, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    city
                    callToActionText
                }
            }
        }
    }
`