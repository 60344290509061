import React, { useState } from 'react'
import "./Introduction.css";

function Introduction(props) {
    const data = props;

    return (
        <div>
            <div className={` ${data.noImage ? "background-about-container3" : "background-about-container2"} background-about-container overflow-hidden relative`}>
                <div className="flex justify-center items-center trans-filter ">
                    <div>
                        <h1>{data.title}</h1>
                    </div>
                </div>
                {/* {console.log(data.backImg.imageFile.childImageSharp)} */}
                {/* <img src={data.backImg.imageFile.childImageSharp.base64} className="intro-bg" /> */}
                {
                    !data.noImage && (
                        <picture>
                            <source type="image/webp" srcSet={data.backImg.imageFile.childImageSharp.fluid.srcSetWebp} className="intro-bg" />
                            <img srcSet={data.backImg.imageFile.childImageSharp.fluid.srcSet} alt={data.backImg.altText}  className="intro-bg top-0" />
                        </picture>
                    )
                }

                {/* <Img fluid={data.backImg.imageFile.childImageSharp.fluid} loading={"eager"} className="intro-bg" /> */}
            </div>
        </div>
    )
}

export default Introduction;